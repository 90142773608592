import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//Reducers
import { setUserConfig, setProveedorConfig } from "../../../reducers/userConfigSlice";
//Components
import { Reservas } from "./sections/reservas";
import { Bussiness } from "./sections/bussiness";
import { Perfil } from "./sections/perfil";
//API
import { getWebConfiguration, getProveedorConfiguration } from "../../../api/requests";
//Styles
import "./../styles.css";

function Clientes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);
  const activeSection = useSelector((state) => state.aplicationConfig.adm.sectionActive);
  //const [activeSection, setActiveSection] = useState("dashboard");
  const [webConfiguration, setWebConfiguration] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [modal, showModal] = useState(false);
  const [countries, setCountries] = useState({});
  const [countriesStates, setCountriesStates] = useState({});

  const getWebConfig = async () => {
    console.log("getWebConfig");
    await getWebConfiguration().then((res) => {
      console.log(res);
      setWebConfiguration(res);
    });
  };

  /*const Menu = () => {
    return (
      <div className="menuProveedores">
        <div className={activeSection === "reservas" ? "activeSection" : ""} onClick={() => setActiveSection("vouchers-client")}>
          Reservas
        </div>
        <div className={activeSection === "bussiness" ? "activeSection" : ""} onClick={() => setActiveSection("billing-client")}>
          Facturación
        </div>
        <div className={activeSection === "perfil" ? "activeSection" : ""} onClick={() => setActiveSection("profile")}>
          Perfil
        </div>
        <div style={{ color: "darkred" }} onClick={() => logout()}>
          Salir
        </div>
      </div>
    );
  };*/

  const logout = () => {
    dispatch(setUserConfig({ logged: false, userData: {} }));
    navigate("/login");
  };

  const Section = () => {
    switch (activeSection) {
      case "vouchers-client":
        return <Reservas webConfiguration={webConfiguration} />;
      case "billing-client":
        return <Bussiness />;

      case "profile":
        return <Perfil />;
      default:
        break;
    }
  };

  const handleModalMessage = (msg) => {
    console.log("handleModalMessage");
    if (msg !== "") {
      setModalMessage(msg);
      showModal(true);
    } else {
      setModalMessage("");
      showModal(false);
    }
  };

  const getClientConfig = async () => {
    const data = {
      id: userData.id,
    };
    await getProveedorConfiguration(data).then((res) => {
      setWebConfiguration(res);
      dispatch(setProveedorConfig({ experiencias: res.proveedor.experiencias }));
      setCountries(res.proveedor.countries);
      setCountriesStates(res.proveedor.countriesStates);
      handleModalMessage("");
    });
  };

  useEffect(() => {
    getWebConfig();
    getClientConfig();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin">
      <div className="contentStretch">
        <Section />
      </div>
    </div>
  );
}

export default Clientes;
