import React from "react";
import Card from "../../components/card";
import "./style.css";

import iconAtraction from "./../../assets/icons/attractions.svg";
import iconDining from "./../../assets/icons/dining.svg";
import iconForest from "./../../assets/icons/forest.svg";
import iconExplore from "./../../assets/icons/explore.svg";

export const FeaturedList = ({ experiencias, tipoExperiencias, tipoExperienciaSeleccionada, setTipoExperienciaSeleccionada }) => {
  const iconsMenu = [{ icon: iconAtraction }, { icon: iconDining }, { icon: iconForest }, { icon: iconExplore }];
  const MenuTipoExperiencias = () => {
    return (
      <div className="bloqueTipoExperiencias">
        {tipoExperiencias.map((e, index) => {
          let active = "bloqueTipoExperiencia";
          if (tipoExperienciaSeleccionada === e.id.toString()) active = "experienciaSeleccionada";
          return (
            <div
              key={"tipoexp" + index}
              className={active + " d-flex justify-content-center"}
              onClick={() => setTipoExperienciaSeleccionada(e.id.toString())}
            >
              <img src={iconsMenu[index].icon} alt="atraction icon" width="30px" />
              <div>{e.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="rowContent">
      {tipoExperiencias.length > 0 && <MenuTipoExperiencias />}
      <div className="contentStretch">
        <div className="featuredListSubtitle">Experiencias culturales inolvidables</div>
        <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", paddingBottom: "80px" }}>
          {experiencias &&
            experiencias.map((e, index) => {
              return e.categorias.split(",").includes(tipoExperienciaSeleccionada) && <Card key={"exp" + index} e={e} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedList;
