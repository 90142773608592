import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import GoogleMapReact from "google-map-react";
import { GoInfo, GoCheck, GoX } from "react-icons/go";
import { FaMapPin } from "react-icons/fa";
import { GoogleMap } from "./googleMap";
import { DisponibilitySmall } from "../../components/disponibilitySmall";
import FeaturedList from "../../sections/featuredList";
//Api
import { getDataWeb } from "./../../api/requests";
import Card from "../../components/card";
import { Review } from "../../components/review";

export const ExperienceDetails = () => {
  const experience = useSelector((state) => state.aplicationConfig.productActive);
  const [experiencias, setExperiencias] = useState([]);

  const mockReviews = [
    {
      id: 0,
      name: "Daniel",
      rating: 5,
      date: "17 de febrero de 2023",
      city: "Reino Unido",
      text: "Fue un viaje muy agradable y, de camino, paramos en una antigua acrópolis que data del año 1600 a. C. Fue surrealista sentarse en los mismos escalones donde se encuentra un teatro hace muchos siglos. Una vez que llegamos a Sounion, pudimos ver el Templo de Poseidón y la vista era increíble. Hay una cafetería encantadora allí para tomar un refrigerio y, una vez que estás en la colina junto al templo, no ves nada más que las vistas más increíbles del Egeo. Athina, nuestra anfitriona, estaba increíblemente bien informada.",
    },
    {
      id: 1,
      name: "Raquel",
      rating: 4,
      date: "19 de febrero de 2023",
      city: "Reino Unido",
      text: "Stavroula fue una guía encantadora, interesante y con mucho conocimiento y un gran sentido del humor. Hizo que el trayecto de 2 horas pasara rápido. El viaje tuvo la duración justa, con una parada sorpresa en un teatro antiguo increíble. El cabo Sunión y el templo eran impresionantes, y tuvimos mucho tiempo para disfrutar de la puesta de sol. Mereció la pena y fue una experiencia increíble por un precio razonable. El conductor de la pequeña y muy bonita furgoneta también fue excelente.",
    },
    {
      id: 2,
      name: "Maria",
      rating: 5,
      date: "20 de febrero de 2023",
      city: "Reino Unido",
      text: "Muy bonita excursión. Nos recogieron en la salida del metro Halandri en un minibús. Tuvimos una excelente guía durante toda esta excursión. Agradezco a Stavroula por compartir con nosotros su pasión por la historia y la arqueología griegas. Empezamos visitando el teatro más antiguo de Europa. Luego fuimos al Cabo Sunión para ver el Templo de Poseidón. El templo es magnífico y la puesta de sol fue muy hermosa aunque no duró mucho debido a las muchas nubes.",
    },
  ];

  const getAllDataWeb = async () => {
    await getDataWeb().then((res) => {
      console.log(res);
      setExperiencias(res["allExperiencies"]);
    });
  };

  useEffect(() => {
    getAllDataWeb();
  }, []);

  const ExperienceInformation = () => {
    const ExperienceContainer = ({ data }) => {
      return (
        <div className="experienceContainer">
          <div className="experienceContainerTitle">{data.title}</div>
          <div className="experienceContainerContent">{data.content}</div>
        </div>
      );
    };
    return (
      <>
        <div className="experienceInformation">
          <ExperienceContainer
            data={{
              title: "Qué incluye",
              content: (
                <>
                  {experience.incluye.split(",").map((e) => (
                    <div>
                      <GoCheck style={{ color: "green" }} /> {e}
                    </div>
                  ))}
                  {experience.noincluye.split(",").map((e) => (
                    <div>
                      <GoX style={{ color: "red" }} /> {e}
                    </div>
                  ))}
                </>
              ),
            }}
          />

          <ExperienceContainer
            data={{
              title: "Información",
              content: (
                <>
                  {experience.adiccional.split(",").map((e) => (
                    <div>- {e}</div>
                  ))}
                </>
              ),
            }}
          />
        </div>
      </>
    );
  };

  const ExperienceMapLocation = () => {
    return (
      <>
        <div style={{ display: "block", width: "100%", padding: "10px" }}>
          <div style={{ width: "100%", height: "400px", backgroundColor: "transparent" }}>
            <GoogleMap lat={parseFloat(experience.latitud)} lng={parseFloat(experience.longitud)} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="rowContent">
      <div className="contentStretch">
        <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", padding: "2%" }}>
          <div className="productBody">
            <p style={{ fontSize: "1.125rem", lineHeight: "160%" }}>{experience.largedesc}</p>

            <h3>Disponibilidad</h3>
            <DisponibilitySmall />

            <h3>Información y detalles</h3>
            <div className="row">
              <div className="col-md-6">
                <ExperienceMapLocation />
              </div>
              <div className="col-md-6">
                <ExperienceInformation />
              </div>
            </div>
            <h3>Otras sugerencias</h3>

            <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", paddingBottom: "80px" }}>
              {experiencias &&
                experiencias.map((e, index) => {
                  return index < 4 && e.categorias.split(",") && <Card key={"exp" + index} e={e} />;
                })}
            </div>
            <h3>Opiniones</h3>
            <Review data={mockReviews} />

            <div style={{ height: "50px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
