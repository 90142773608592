import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setProveedorConfig } from "../../../reducers/userConfigSlice";
//Components
import { Dashboard } from "./sections/dashboard";
import { Bussiness } from "./sections/bussiness";
import { Perfil } from "./sections/perfil";
import { Experiences } from "./sections/experiences";
import { Sales } from "./sections/sales";
import { Bookings } from "./sections/bookings";
import { ModalMessage } from "../../../components/modalMessage";
import { Alert } from "../../../components/alert";
//API
import { getProveedorConfiguration, getClientTicandoData, updateExperiences } from "../../../api/requests";
//Styles
import "./../styles.css";

function Proveedores() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);
  const activeSection = useSelector((state) => state.aplicationConfig.adm.sectionActive);
  //const [activeSection, setActiveSection] = useState("dashboard");

  const [webConfiguration, setWebConfiguration] = useState({});

  const [countries, setCountries] = useState({});
  const [countriesStates, setCountriesStates] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [modal, showModal] = useState(false);

  const handleModalMessage = (msg) => {
    console.log("handleModalMessage");
    if (msg !== "") {
      setModalMessage(msg);
      showModal(true);
    } else {
      setModalMessage("");
      showModal(false);
    }
  };

  const getProveedorConfig = async () => {
    const data = {
      id: userData.id,
    };
    await getProveedorConfiguration(data).then((res) => {
      setWebConfiguration(res);
      dispatch(setProveedorConfig({ experiencias: res.proveedor.experiencias }));
      setCountries(res.proveedor.countries);
      setCountriesStates(res.proveedor.countriesStates);
      handleModalMessage("");
    });
  };

  const getTicandoData = async () => {
    console.log("CALLING TICANDO DATA");
    await getClientTicandoData(userData.publicApiToken).then((res) => {
      updateExperiencesFromTicando(res);
    });
  };

  const updateExperiencesFromTicando = async (data) => {
    await updateExperiences({ id: userData.id, data: data }).then((res) => {
      if (res) {
        setTimeout(() => {
          getProveedorConfig();
        }, 1500);
      }
    });
  };

  const SectionProveedores = () => {
    switch (activeSection) {
      case "dashboard-provider":
        return <Dashboard />;
      case "bookings-provider":
        return <Bookings />;
      case "bussiness-provider":
        return <Bussiness handleModalMessage={handleModalMessage} getProveedorConfig={getProveedorConfig} getTicandoData={getTicandoData} />;
      case "profile":
        return <Perfil getProveedorConfig={getProveedorConfig} />;
      case "experiences-provider":
        return (
          <Experiences
            countries={countries}
            countriesStates={countriesStates}
            webConfiguration={webConfiguration}
            getProveedorConfig={getProveedorConfig}
          />
        );
      case "sales-provider":
        return <Sales />;
      default:
        return <Dashboard />;
    }
  };

  useEffect(() => {
    getProveedorConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin">
      <div className="contentStretch">
        <SectionProveedores />
        <Alert />
        {modal === true && <ModalMessage msg={modalMessage} callback={handleModalMessage} />}
      </div>
    </div>
  );
}

export default Proveedores;
