import StarRatings from "react-star-ratings";
import "./style.css";

export const Review = ({ data }) => {
  return (
    <div className="row">
      {data.map((review) => (
        <div className="col-md-12">
          <div className="review">
            <div className="review-header">
              <div className="review-header-left">
                <div className="review-header-icon">{review.name[0]}</div>
                <div className="review-header-name">{review.name}</div>
              </div>
              <div className="review-header-stars">
                <StarRatings
                  rating={review.rating}
                  numberOfStars={5}
                  name="rating"
                  starRatedColor={"gold"}
                  starDimension={"20px"}
                  starSpacing={"2px"}
                />
              </div>
            </div>
            <div className="review-body">{review.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
