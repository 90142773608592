//Redux
import { useSelector } from "react-redux";
//Components
import { Badget } from "../../../../components/charts/badget";
import BarComponent from "../../../../components/charts/barComponent";
import PieComponent from "../../../../components/charts/pieComponent";
//Icons
import { FiDollarSign, FiCreditCard, FiShoppingCart } from "react-icons/fi";
import { CiDiscount1, CiPassport1 } from "react-icons/ci";
import { ImCancelCircle } from "react-icons/im";
import { Table } from "../../../../components/charts/table";
//Helpers
import { parseExperienceValues } from "./../../../../helpers/functions";

// PieChart data mock =========
const dataPie1 = [
  { name: "Día", value: 400 },
  { name: "Tarde", value: 270 },
  { name: "Noche", value: 200 },
];
const COLORSPie1 = ["#0088FE", "#94a4d9", "#150088"];
const dataPie2 = [
  { name: "Cultura", value: 120 },
  { name: "Gastronomía", value: 57 },
  { name: "Naturaleza", value: 310 },
  { name: "Aventura", value: 200 },
];
const COLORSPie2 = ["#0088FE", "#94a4d9", "#150088", "#0e0735"];
//==============================

// BarChart data mock ===========
const dataBar1 = [
  {
    name: "08/05",
    reservas: 4000,
    ventas: 2100,
  },
  {
    name: "09/05",
    reservas: 3000,
    ventas: 1398,
  },
  {
    name: "10/05",
    reservas: 4000,
    ventas: 1298,
  },
  {
    name: "11/05",
    reservas: 4500,
    ventas: 2198,
  },
  {
    name: "12/05",
    reservas: 3500,
    ventas: 1898,
  },

  {
    name: "13/05",
    reservas: 3300,
    ventas: 1298,
  },
  {
    name: "14/05",
    reservas: 3200,
    ventas: 1198,
  },
  {
    name: "15/05",
    reservas: 3700,
    ventas: 1798,
  },
  {
    name: "16/05",
    reservas: 3900,
    ventas: 1298,
  },
  {
    name: "17/05",
    reservas: 3200,
    ventas: 1898,
  },
  {
    name: "18/05",
    reservas: 3500,
    ventas: 1898,
  },
  {
    name: "19/05",
    reservas: 4200,
    ventas: 2408,
  },
  {
    name: "20/05",
    reservas: 4300,
    ventas: 2608,
  },
];
//===========================

// Table data mock ===========
const nameHeadersTable = [
  { key: "experience", name: "Experiencia" },
  { key: "date", name: "Fecha" },
  { key: "value", name: "Importe" },
];
const valuesTable = [
  { experience: 52, date: "24/05/2024 15:55", value: "150€" },
  { experience: 53, date: "24/05/2024 14:55", value: "550€" },
  { experience: 54, date: "24/05/2024 14:54", value: "150€" },
  { experience: 54, date: "24/05/2024 14:35", value: "350€" },
  { experience: 53, date: "24/05/2024 14:22", value: "350€" },
  { experience: 52, date: "24/05/2024 14:10", value: "230€" },
  { experience: 53, date: "24/05/2024 13:55", value: "230€" },
  { experience: 54, date: "24/05/2024 12:55", value: "230€" },
  { experience: 55, date: "24/05/2024 12:50", value: "220€" },
  { experience: 55, date: "24/05/2024 12:46", value: "330€" },
];
//===========================

export const Dashboard = () => {
  const experiencias = useSelector((state) => state.aplicationConfig.proveedorConfig.experiencias);
  return (
    <div className="section">
      <div className="row">
        <div className="col-3">
          <div className="row">
            <div className="col-12 titleDashboard">Horario</div>
            <div style={{ width: "100%", height: "200px" }}>
              <PieComponent full={false} data={dataPie1} COLORS={COLORSPie1} />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-12 titleDashboard">Resumen</div>
            <div className="col-4 mt-3">
              <Badget value={"2.550€"} icon={<FiDollarSign />} note={"Ventas"} color={"#0088FE"} />
            </div>
            <div className="col-4 mt-3">
              <Badget value={"6.550€"} icon={<FiShoppingCart />} note={"Reservas"} color={"#150088"} />
            </div>
            <div className="col-4 mt-3">
              <Badget value={"140€"} icon={<ImCancelCircle />} note={"Reembolsos"} color={"darkred"} />
            </div>
            <div className="col-4 mt-3">
              <Badget value={"450€"} icon={<FiCreditCard />} note={"Tarjetas regalo"} color={"orange"} />
            </div>
            <div className="col-4 mt-3">
              <Badget value={"342€"} icon={<CiDiscount1 />} note={"Descuentos"} color={"darkgreen"} />
            </div>
            <div className="col-4 mt-3">
              <Badget value={"85€"} icon={<CiPassport1 />} note={"Abonos"} color={"black"} />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-12 titleDashboard">Categorías</div>
            <div style={{ width: "100%", height: "200px" }}>
              <PieComponent full={false} data={dataPie2} COLORS={COLORSPie2} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 titleDashboard">Reservas vs Ventas</div>
        <div className="col-12">
          <div style={{ width: "100%", height: "300px" }}>
            <BarComponent data={dataBar1} />
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 titleDashboard">Últimas ventas</div>
        <div className="col-12">
          <Table nameHeaders={nameHeadersTable} values={parseExperienceValues(valuesTable, experiencias)} />
        </div>
      </div>
    </div>
  );
};
